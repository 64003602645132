import { Link } from "react-router-dom";
import { Container } from "../../components/UI/Container/Container"
import { Product } from "../../components/Parts/Product/Product";

import styles from "./Products.module.css"
import { ProductHelmet } from "../../components/Helmets/ProductHelmet";

export const Products = ({ loading, data }) => {
    return (
        <Container>
            <ProductHelmet title={`Каталог ${loading? "" : data.subcategory.title} | Контур СПб`}/>
            <div className={styles.container}>
                <div className={styles.links}>
                    <Link to='/'>главная</Link> - <Link to='/catalogue'>каталог</Link> {loading ?
                        (<></>)
                        : (<Link to='..'>&nbsp;- {data.subcategory.title.toLowerCase()}</Link>)}
                </div>
                <h1 className={styles.header}>Каталог {loading ? "продукции" : (data.subcategory.title)}</h1>
                <div className={styles.products}>
                    {loading ? (<>Загрузка...</>) : (data.products.map((data, index) => {
                        return (
                            <Product
                                key={index}
                                id={data.id}
                                title={data.title}
                                image_urls={data.images_url}
                                price={data.price}
                                description={data.description}
                                parameters={data.parameters}
                            />
                        )
                    }))}
                </div>
            </div>
        </Container>
    )
}